import React from "react"

import Container from "../components/Container"
import Layout from "../components/Layout"
import Section from "../components/Section"
import Column from "../components/Column"
import Row from "../components/Row"
import Card from "../components/Card"
import Header from "../components/Header"
import Text from "../components/Text"
import Button from "../components/Button"

import PeoplePng from "../images/people.png"
import EventsPng from "../images/events.png"
import ChatPng from "../images/chat.png"
import MobilePng from "../images/mobile.png"
import ProgressPng from "../images/progress.png"
import DesktopPng from "../images/Desktop.jpg"
import BgPng from "../images/bg.png"

import SEO from "../components/seo"

import styles from './index.module.scss'

const openChaport = () => {
  if (window.chaport) {
    window.chaport.q('open')
  }
};

const IndexPage = () => (
  <Layout className={styles.main} style={{ backgroundImage: `url(${BgPng})`}}>
    <SEO
      title="Goaly"
      meta={[
        { name: 'description', content: 'Goaly - Technologia dla akademii piłkarskich' },
        { name: 'viewport', content: 'user-scalable=no, width=device-width, initial-scale=1.0"'}
      ]}
    />
    <Container>
      <Section>
        <Row>
          <Column size={2}>
            <Header priority={1}>
              Technologia dla akademii piłkarskich
            </Header>
            <Text size="large" color="grey" style={{marginBottom: '2rem'}}>
              Goaly to nowoczesna aplikacja która wprowadzi Twoją szkołę piłkarską na światowy poziom
            </Text>
            <Text size="medium" color="green" bold style={{marginBottom: '4rem'}}>
              Przygotowaliśmy dla Ciebie aplikację która pomoże Ci w kompleksowym prowadzeniu szkoły piłkarskiej.
            </Text>
            <div>
              <Button href="https://app.goaly.pl/">
                Zobacz demo
              </Button>
              <Button href="mailto:kontakt@goaly.pl">
                Skontaktuj się z nami
              </Button>
            </div>
          </Column>
          <Column size={1}/>
        </Row>
      </Section>
      <Section>
        <Card>
          <Row>
            <Column>
              <Text size="large" color="grey">
                Administratorze, trenerze...
              </Text>
              <Header priority={2}>
                Zarządzaj w komputerze
              </Header>
              <Text size="medium" color="grey">
                W Goaly podstawowe zarządzanie szkołą odbywa się poprzez <strong>panel administracyjny</strong> w aplikacji przeglądarkowej. Dostęp do niej posiadają aministratorzy i trenerzy.
              </Text>
            </Column>
            <Column>
              <Text size="large" color="grey">
                Dla trenera i rodzica
              </Text>
              <Header priority={2}>
                Aplikacja mobilna
              </Header>
              <Text size="medium" color="grey">
                Ponadto wszyscy trenerzy i rodzice mogą korzystać z <strong>aplikacji mobilnej</strong> by mieć wszystkie szczegóły dotyczące swoich grup i podopiecznych zawsze pod ręką.</Text>
            </Column>
          </Row>
        </Card>
      </Section>
      <Section>
        <Row>
          <Column size={2}>
            <Text size="large" color="grey">
              Ludzie szkoły
            </Text>
            <Header priority={2}>
              Trenerzy, grupy, zawodnicy, rodzice
            </Header>
            <Text size="medium" color="grey">
              Zarządzaj w sposób swobodny <strong>trenerami, zawodnikami oraz ich opiekunami</strong>. Twórz grupy i przypisuj opiekunów do zawodników. Dodawaj multimedia, twórz bazę danych kontaktowych.
            </Text>
          </Column>
          <Column size={1}/>
          <img src={PeoplePng} className={styles.imageSection} />
        </Row>
      </Section>
      <Section>
        <Row>
          <Column size={2}>
            <Text size="large" color="grey">
              Treningi i spotkania
            </Text>
            <Header priority={2}>
              Obiekty i kalendarze
            </Header>
            <Text size="medium" color="grey">
              Prowadz bazę dostępnych <strong>obiektów</strong> i planuj <strong>wydarzenia</strong> dla podpopiecznych, ich trenerów i rodziców! Otrzymuj powiadomienia o nadchodzących treningach i sprawdzaj obecność na zajęciach.
            </Text>
          </Column>
          <Column size={1}/>
          <img src={EventsPng} className={styles.imageSection} />
        </Row>
      </Section>
      <Section>
        <Row>
          <Column size={2}>
            <Text size="large" color="grey">
              Gotowy, do startu, start!
            </Text>
            <Header priority={2}>
              Testy i obserwacje
            </Header>
            <Text size="medium" color="grey">
              Wprowadzaj <strong>wyniki testów</strong> ręcznie lub ... przeprowadzaj je somodzielnie korzystając ze swojego telefonu i bazy dostępnych sprawdzianów. Notuj oceny i doceniaj aktywnych zawodników.
            </Text>
          </Column>
          <Column size={1}/>

        </Row>
      </Section>
      <Section>
        <Row>
          <Column size={2}>
            <Text size="large" color="grey">
              Aktywności w szkole
            </Text>
            <Header priority={2}>
              Kontrola rozwoju i progresu
            </Header>
            <Text size="medium" color="grey">
              Notuj i obserwuj <strong>rozwój fizjonomiczny</strong> zawodników. Sprawdzaj ich parametry i porównuj progres młodych adeptów piłki nożnej.
            </Text>
          </Column>
          <Column size={1}/>
          <img src={ProgressPng} className={styles.imageSection} />
        </Row>
      </Section>
      <Section>
        <Row>
          <Column size={2}>
            <Text size="large" color="grey">
              Przepływ informacji
            </Text>
            <Header priority={2}>
              Komunikacja z rodzicami
            </Header>
            <Text size="medium" color="grey">
              Wysyłaj notyfikacje bezpośrednio na urządzenia rodziców. Informuj o zmianach w kalendarzu i <strong>aktywnie prowadź dialog z opiekunami</strong> Twoich zawodników.
            </Text>
          </Column>
          <Column size={1}/>
          <img src={ChatPng} className={styles.imageSection} />
        </Row>
      </Section>
      <Section>
        <Row>
          <Column size={2}>
            <Text size="large" color="grey">
              Zawsze pod ręką
            </Text>
            <Header priority={2}>
              Aplikacje mobilne
            </Header>
            <Text size="medium" color="grey">
              Goaly jest dostępne także jako aplikacja mobilna na telefony z systemem Android i iOS. Badź zawsze na bieżąco i miej swoją szkołę pod ręką.
            </Text>
          </Column>
          <Column size={1}/>
          <img src={MobilePng} className={styles.imageSection} />
        </Row>
      </Section>
      <Section>
        <Row style={{marginBottom: '2rem'}}>
          <Column>
            <Text size="large" color="grey">
              Inne możliwości Goaly...
            </Text>
          </Column>
        </Row>
        <Row style={{marginBottom: '2rem'}}>
          <Column>
            <div>
              <Button>
                Fotogalerie i multimedia
              </Button>
              <Button>
                Aktualności
              </Button>
              <Button>
                Integracja ze stroną WWW
              </Button>
              <Button>
                Artykuły
              </Button>
              <Button>
                Oceny okresowe
              </Button>
            </div>
          </Column>
        </Row>
        <Row style={{marginBottom: '2rem'}}>
          <Column>
            <Text size="large" color="grey">
              A już wkrótce...
            </Text>
          </Column>
        </Row>
        <Row>
          <Column>
            <div>
              <Button>
                Płatności
              </Button>
              <Button>
                Ankiety
              </Button>
              <Button>
                Dokumenty
              </Button>
              <Button>
                Baza wiedzy
              </Button>
              <Button>
                Chat grupowy
              </Button>
            </div>
          </Column>
        </Row>
      </Section>
      <Section>
        <Card className={styles.demo} style={{ backgroundImage: `url(${DesktopPng})` }}>
          <Row>
            <Column>
              <Text size="large" color="green">
                Chcesz zobaczyć Goaly w akcji?
              </Text>
              <Header priority={2}>
                Sprawdź nasze demo!
              </Header>
              <Text size="medium" color="grey" style={{marginBottom: '2rem'}}>
                Chciałbyś sprawdzić jak radzi sobie Goaly w akcji? Specjalnie na tę okoliczność przygotowaliśmy demo.
                Użyj przycisku poniżej by przejść do logowania. Jako dane podaj adres email <strong>demo@goaly.pl</strong> i hasło <strong>demo123</strong>
              </Text>
              <div>
                <Button href="https://app.goaly.pl/">
                  Zaloguj się do demo
                </Button>
              </div>
            </Column>
            <Column />
          </Row>
        </Card>
      </Section>
      <Section>
        <Row style={{marginBottom: '4rem'}}>
          <Column>
            <Text size="large" color="green">
              Najpopularniejsze pytania o Goaly?
            </Text>
          </Column>
        </Row>
        <Row style={{marginBottom: '4rem'}}>
          <Column>
            <Text size="medium" bold style={{marginBottom: '2rem'}}>
              Jak mogę dołączyć do Goaly?
            </Text>
            <Text size="medium" color="grey">
              Szkołę w Goaly może założyć każdy. Przejdż do formularza rejestracyjnego <a href="https://app.goaly.pl/auth/signup">https://app.goaly.pl/auth/signup</a> i utwórz konto. Przy pierwszym logowaniu zostaniesz poproszony o utworzenie nowej szkoły.
            </Text>
          </Column>
          <Column>
            <Text size="medium" bold style={{marginBottom: '2rem'}}>
              Czy Goaly coś kosztuje?
            </Text>
            <Text size="medium" color="grey">
              Póki co Goaly jest darmowe! Ciągle się rozwijamy, zależy nam na jak najlepszej jakości szkolenia młodych adeptów piłki nożnej i jak największej ilości szkół. To co dla nas ważne to by być fair. Jeżeli zaczniemy myśleć o wprowadzeniu opłat to będziesz pierwszą osobą z którą się tym podzielimy.
            </Text>
          </Column>
        </Row>
        <Row>
          <Column>
            <Text size="medium" bold style={{marginBottom: '2rem'}}>
              Mam więcej pytań, jak do Was napisać?
            </Text>
            <Text size="medium" color="grey">
              Skorzystaj z okrągłego przycisku w prawym dolnym rogu ekranu by rozpocząć z nami chat lub wyślij maila na kontakt@goaly.pl.
            </Text>
          </Column>
          <Column>
            <Text size="medium" bold style={{marginBottom: '2rem'}}>
              Czy Goaly się rozwija?
            </Text>
            <Text size="medium" color="grey">
              Tak! Cały czas mamy plany i głowy pełne pomysłów. Jeżeli masz swój lub na czymś Twojej szkole szczególnie zależy daj nam znać!
            </Text>
          </Column>
        </Row>
      </Section>
      <Section>
        <Card color="green">
          <Row style={{marginBottom: '2rem'}}>
            <Column>
              <Text size="large" bold color="white">
                Chcesz korzystać z Goaly?
              </Text>
              <Text size="large" color="white">
                Wspaniale!
              </Text>
            </Column>
          </Row>
          <Row>
            <Column>
              <Text size="large" color="white" style={{marginBottom: '1rem'}}>
                Jeżeli prowadzisz szkołę
              </Text>
              <Text size="medium" color="white">
                Będzie nam wyjątkowo miło jeżeli Twoja szkoła dołączy do Goaly! Jeżeli masz jakieś pytania daj nam znać korzystając z przycisku poniżej lub wyślij maila na kontakt@goaly.pl. Jeżeli jesteś zdecydowany przejdź bezpośrednio do rejestracji
              </Text>
              <div style={{marginTop: '2rem'}}>
                <Button href="https://app.goaly.pl/auth/signup">
                  Przejdź do rejestracji
                </Button>
                <Button href="mailto:kontakt@goaly.pl">
                  Skontaktuj się z nami
                </Button>
              </div>
            </Column>
            <Column>
              <Text size="large" color="white" style={{marginBottom: '1rem'}}>
                Jeżeli jesteś rodzicem
              </Text>
              <Text size="medium" color="white">
                Pokaż trenerom swoich pociech stronę Goaly! Niech rzucą okiem na możliwości i sprawdzą czy Goaly może im się przydać w ich organizacji.
              </Text>
            </Column>
          </Row>
        </Card>
      </Section>
    </Container>
  </Layout>
);

export default IndexPage
