import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import styles from "./Text.module.scss"

const Text = ({children, size, bold, color, style}) => {
	return (
		<div className={styles.root} style={style}>
			<p className={cx(styles[size], bold && styles.bold, styles[color])}>
				{children}
			</p>
		</div>
	)
};

Text.propTypes = {
	children: PropTypes.node.isRequired,
	size: PropTypes.string,
	bold: PropTypes.bool,
	color: PropTypes.string,
	style: PropTypes.object,
};

Text.defaultProps = {
	size: 'medium',
	bold: false,
	color: 'black',
	style: null
};

export default Text
