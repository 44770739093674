import React from "react"
import PropTypes from "prop-types"

import styles from "./Row.module.scss"

const Row = ({children, size, style}) => {
	return (
		<div className={styles.root} style={{...style, flex: size}}>
			{children}
		</div>
	)
};

Row.propTypes = {
	children: PropTypes.node.isRequired,
	size: PropTypes.number
};

Row.defaultProps = {
	size: 1
};

export default Row
