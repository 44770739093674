import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import styles from "./Card.module.scss"

const Card = ({ children, color, style, className }) => {
	return (
		<div className={cx([styles.root, styles[color], className])} style={style}>
			{children}
		</div>
	)
};

Card.propTypes = {
	children: PropTypes.node.isRequired,
	color: PropTypes.string
};

Card.defaultProps = {
	color: 'white'
};

export default Card
