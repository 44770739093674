/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Logo from "../Logo";
import Text from "../Text";

import PolitykaPrywatnosci from "../../docs/polityka-prywatnosci.pdf";
import Regulamin from "../../docs/regulamin.pdf";

import styles from "./Container.module.scss"

const Container = ({children}) => {
  return (
    <div
      className={styles.root}
    >
      <Logo />
      <main>{children}</main>
      <footer>
        <Logo />
        <div style={{marginTop: '2rem', marginBottom: '2rem'}}>
          <a href={PolitykaPrywatnosci}>
            <Text color="grey">Polityka prywatności</Text>
          </a>
          <a href={Regulamin}>
            <Text color="grey">Regulamin</Text>
          </a>
        </div>
        <Text color="grey">
          © 2018 Goaly, wszelkie prawa zatrzeżone
        </Text>
      </footer>
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Container
