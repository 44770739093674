import React from "react"
import PropTypes from "prop-types"

import styles from "./Header.module.scss"

const Header = ({children, priority}) => {
	const CustomTag = `h${priority}`;
	return (
		<div className={styles.root}>
			<CustomTag>
				{children}
			</CustomTag>
		</div>
	)
};

Header.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Header
