import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({
    color,
    width,
}) => (
    <svg width={width} height={46*width/120} viewBox="0 0 125 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.704 9.84001C23.536 9.84001 24.224 10.112 24.768 10.656C25.312 11.2 25.584 11.904 25.584 12.768V34.128C25.584 38.032 24.368 40.912 21.936 42.768C19.536 44.656 16.48 45.6 12.768 45.6C11.808 45.6 10.48 45.44 8.784 45.12C7.12 44.8 5.936 44.48 5.232 44.16C3.664 43.488 2.88 42.56 2.88 41.376C2.88 41.152 2.928 40.896 3.024 40.608C3.216 39.904 3.536 39.36 3.984 38.976C4.464 38.624 4.976 38.448 5.52 38.448C5.84 38.448 6.144 38.496 6.432 38.592C6.56 38.624 7.344 38.912 8.784 39.456C10.256 40.032 11.584 40.32 12.768 40.32C15.136 40.32 16.896 39.84 18.048 38.88C19.232 37.952 19.824 36.624 19.824 34.896V32.592C19.12 33.584 18.048 34.48 16.608 35.28C15.2 36.08 13.68 36.48 12.048 36.48C9.808 36.48 7.76 35.92 5.904 34.8C4.08 33.648 2.64 32.064 1.584 30.048C0.528 28.032 0 25.744 0 23.184C0 20.624 0.512 18.336 1.536 16.32C2.592 14.272 4.016 12.688 5.808 11.568C7.632 10.416 9.648 9.84001 11.856 9.84001C13.488 9.84001 15.008 10.176 16.416 10.848C17.824 11.52 18.96 12.336 19.824 13.296V12.768C19.824 11.936 20.096 11.248 20.64 10.704C21.184 10.128 21.872 9.84001 22.704 9.84001ZM12.768 31.2C14.912 31.2 16.656 30.448 18 28.944C19.376 27.408 20.064 25.488 20.064 23.184C20.064 20.88 19.376 18.96 18 17.424C16.656 15.888 14.912 15.12 12.768 15.12C10.624 15.12 8.88 15.888 7.536 17.424C6.192 18.96 5.52 20.88 5.52 23.184C5.52 25.488 6.192 27.408 7.536 28.944C8.88 30.448 10.624 31.2 12.768 31.2Z" fill={color}/>
        <path d="M55.5495 23.184C55.5495 25.744 54.9735 28.048 53.8215 30.096C52.6695 32.112 51.0855 33.68 49.0695 34.8C47.0855 35.92 44.8935 36.48 42.4935 36.48C40.0615 36.48 37.8535 35.92 35.8695 34.8C33.8855 33.68 32.3175 32.112 31.1655 30.096C30.0135 28.048 29.4375 25.744 29.4375 23.184C29.4375 20.624 30.0135 18.336 31.1655 16.32C32.3175 14.272 33.8855 12.688 35.8695 11.568C37.8535 10.416 40.0615 9.84001 42.4935 9.84001C44.8935 9.84001 47.0855 10.416 49.0695 11.568C51.0855 12.688 52.6695 14.272 53.8215 16.32C54.9735 18.336 55.5495 20.624 55.5495 23.184ZM49.7895 23.184C49.7895 21.616 49.4535 20.224 48.7815 19.008C48.1415 17.76 47.2615 16.8 46.1415 16.128C45.0535 15.456 43.8375 15.12 42.4935 15.12C41.1495 15.12 39.9175 15.456 38.7975 16.128C37.7095 16.8 36.8295 17.76 36.1575 19.008C35.5175 20.224 35.1975 21.616 35.1975 23.184C35.1975 24.752 35.5175 26.144 36.1575 27.36C36.8295 28.576 37.7095 29.52 38.7975 30.192C39.9175 30.864 41.1495 31.2 42.4935 31.2C43.8375 31.2 45.0535 30.864 46.1415 30.192C47.2615 29.52 48.1415 28.576 48.7815 27.36C49.4535 26.144 49.7895 24.752 49.7895 23.184Z" fill={color}/>
        <path d="M81.1459 9.84001C81.9779 9.84001 82.6659 10.112 83.2099 10.656C83.7539 11.2 84.0259 11.904 84.0259 12.768V33.072C84.0259 33.904 83.7539 34.608 83.2099 35.184C82.6659 35.728 81.9779 36 81.1459 36C80.3139 36 79.6259 35.728 79.0819 35.184C78.5699 34.64 78.2979 33.952 78.2659 33.12C77.4659 34.048 76.3779 34.848 75.0019 35.52C73.6579 36.16 72.2339 36.48 70.7299 36.48C68.5219 36.48 66.5219 35.92 64.7299 34.8C62.9379 33.648 61.5139 32.064 60.4579 30.048C59.4339 28.032 58.9219 25.744 58.9219 23.184C58.9219 20.624 59.4339 18.336 60.4579 16.32C61.4819 14.272 62.8739 12.688 64.6339 11.568C66.4259 10.416 68.3939 9.84001 70.5379 9.84001C72.0739 9.84001 73.5139 10.128 74.8579 10.704C76.2019 11.248 77.3379 11.952 78.2659 12.816V12.768C78.2659 11.936 78.5379 11.248 79.0819 10.704C79.6259 10.128 80.3139 9.84001 81.1459 9.84001ZM71.4499 31.2C73.5299 31.2 75.2259 30.448 76.5379 28.944C77.8499 27.408 78.5059 25.488 78.5059 23.184C78.5059 20.88 77.8499 18.96 76.5379 17.424C75.2259 15.888 73.5299 15.12 71.4499 15.12C69.4019 15.12 67.7219 15.888 66.4099 17.424C65.0979 18.96 64.4419 20.88 64.4419 23.184C64.4419 25.488 65.0819 27.408 66.3619 28.944C67.6739 30.448 69.3699 31.2 71.4499 31.2Z" fill={color}/>
        <path d="M96.5081 33.072C96.5081 33.904 96.2201 34.608 95.6441 35.184C95.1001 35.728 94.4121 36 93.5801 36C92.7801 36 92.1081 35.728 91.5641 35.184C91.0201 34.608 90.7481 33.904 90.7481 33.072V3.40801C90.7481 2.57601 91.0201 1.88801 91.5641 1.34401C92.1401 0.76801 92.8441 0.480011 93.6761 0.480011C94.4761 0.480011 95.1481 0.76801 95.6921 1.34401C96.2361 1.88801 96.5081 2.57601 96.5081 3.40801V33.072Z" fill={color}/>
        <path d="M121.559 10.32C122.391 10.32 123.078 10.608 123.622 11.184C124.166 11.728 124.438 12.416 124.438 13.248V33.648C124.438 37.808 123.318 40.832 121.078 42.72C118.839 44.64 115.847 45.6 112.103 45.6C111.015 45.6 109.895 45.504 108.743 45.312C107.622 45.152 106.711 44.928 106.007 44.64C104.438 43.968 103.655 43.04 103.655 41.856C103.655 41.632 103.702 41.376 103.799 41.088C103.991 40.384 104.311 39.84 104.758 39.456C105.239 39.104 105.751 38.928 106.295 38.928C106.615 38.928 106.918 38.976 107.206 39.072C107.43 39.168 107.814 39.328 108.358 39.552C108.934 39.776 109.526 39.952 110.134 40.08C110.774 40.24 111.431 40.32 112.103 40.32C114.375 40.32 116.038 39.824 117.094 38.832C118.182 37.872 118.727 36.208 118.727 33.84V33.36C117.062 35.44 114.47 36.48 110.951 36.48C109.286 36.48 107.814 36.096 106.535 35.328C105.255 34.528 104.263 33.44 103.559 32.064C102.854 30.656 102.503 29.056 102.503 27.264V13.248C102.503 12.416 102.774 11.728 103.318 11.184C103.862 10.608 104.55 10.32 105.382 10.32C106.215 10.32 106.903 10.608 107.447 11.184C107.991 11.728 108.262 12.416 108.262 13.248V25.536C108.262 27.552 108.695 29.008 109.559 29.904C110.454 30.768 111.751 31.2 113.447 31.2C115.111 31.2 116.391 30.72 117.286 29.76C118.215 28.768 118.678 27.36 118.678 25.536V13.248C118.678 12.416 118.951 11.728 119.494 11.184C120.038 10.608 120.727 10.32 121.559 10.32Z" fill={color}/>
        <path d="M39.32 3C39.32 1.34315 40.6632 0 42.32 0C43.9769 0 45.32 1.34315 45.32 3V13H39.32V3Z" fill={color}/>
        <path d="M45.32 43C45.32 44.6569 43.9769 46 42.32 46C40.6632 46 39.32 44.6569 39.32 43L39.32 33L45.32 33L45.32 43Z" fill={color}/>
    </svg>

);

Logo.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
};

Logo.defaultProps = {
    color: '#47D07C',
    width: 120,
};

export default Logo;