import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Link} from "gatsby"

import styles from './Button.module.scss';

const Button = ({
	type,
	children,
	shadow,
	color,
	disabled,
	href,
	onClick,
	style
}) => {
	if (href && href.startsWith('/')) {
		return (
			<Link
				className={cx(styles.button, styles[type], styles[color], (disabled && styles.disabled), (shadow && styles.shadow))}
				style={style} to={href}>
				{children}
			</Link>
		);
	}
	return (
		<a
			className={cx(styles.button, styles[type], styles[color], (disabled && styles.disabled), (shadow && styles.shadow))}
			style={style} href={href} onClick={onClick}>
			{children}
		</a>
	);

};

Button.propTypes = {
	children: PropTypes.string,
	type: PropTypes.oneOf(['text', 'icon']),
	shadow: PropTypes.bool,
	color: PropTypes.oneOf(['white', 'green', 'yellow']),
	disabled: PropTypes.bool,
	href: PropTypes.string,
	onClick: PropTypes.func,
	style: PropTypes.shape({}),
};

Button.defaultProps = {
	type: 'text',
	shadow: true,
	color: 'white',
	disabled: false,
	text: '',
	href: undefined,
	onClick: undefined,
	style: null
};

export default Button;
