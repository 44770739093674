/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import styles from "./Section.module.scss"

const Section = ({children}) => {
	return (
		<div
			className={styles.root}
		>
			{children}
		</div>
	)
};

Section.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Section
