import React from "react"
import PropTypes from "prop-types"

import styles from "./Column.module.scss"

const Column = ({ children, size }) => {
	return (
		<div className={styles.root} style={{ flex: size }}>
			{children}
		</div>
	)
};

Column.propTypes = {
	children: PropTypes.node.isRequired,
	size: PropTypes.number
};

Column.defaultProps = {
	size: 1
};

export default Column
